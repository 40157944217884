<!-- 课程类型 -->
<template>
  <div class="flex-col space-y-20 page">

    <div class="flex-col section_3">
      <div class="flex-col items-start group_3">
        <div class="flex-col items-start text-wrapper"><span class="text_2">安证管理</span></div>
      </div>
      <div class="flex-row justify-between group_4">
		<div class=" flex-row">
			
			<div class="flex-row justify-center">
				<span class="text_11">关键字</span>
				<el-input placeholder="请输入安证名称进行搜索" v-model="keyword" clearable class="text_12" style="width: 15rem;"></el-input>
				<div class="flex-col items-center text-wrapper_2" @click="searchBtn()"><span class="text_13">查询</span></div>
				<div class="flex-col items-center text-wrapper_3" @click="resetBtn()"><span class="text_14">重置</span></div>
			</div>
		</div>
		<div class="group_20">
		  <el-button type="danger"  @click="dialogVisible = true">添加安证</el-button>
		</div>
	  </div>
      <div class="flex-col space-y-14 group_7" v-loading="loading" element-loading-text="数据正在加载中">
        <div v-if="list.length > 0">
          <el-table 
          ref="multipleTable"
          :data="list" 
          style="width: auto" 
          :header-cell-style="{background:'#eef0f7bf'}" 
          v-loading="loading" 
          element-loading-text = "数据正在加载中" 
          element-loading-spinner = "el-icon-loading">
                  <el-table-column :reserve-selection="true" type="selection" width="55" align="center"></el-table-column>
            <el-table-column type="index" label="序号" width="auto" align="center"></el-table-column>
                  <el-table-column prop="name" label="安证" width="400px" align="center"></el-table-column>
                  <el-table-column
                        label="安证图片"
                        width="auto">
                        <template slot-scope="scope">
                          <el-image :src="scope.row.imgpath" :preview-src-list="previewImgs" :initial-index="scope.row.index" style="width: 80px; height: 80px;"></el-image>
                        </template>
                   </el-table-column>
                  
				  <el-table-column prop="exp_time" label="安证到期时间" width="auto" align="center">
					  <template slot-scope="scope">
					      <span :class="{ 'red-text': scope.row.is_exp_soon == 1 }">{{ scope.row.exp_time }}</span>
					  </template>
				  </el-table-column>
                  
				  <el-table-column
                        label="操作"
                        width="150">
                        <template slot-scope="scope">
                          <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
                          <el-button @click="detailClick(scope.row)" type="text" size="small">查看</el-button>
                          <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
                        </template>
				   </el-table-column>
		</el-table>
        </div>
        <div v-else>
          <div class="flex-col space-y-22 group_7">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
		
		<el-dialog
		  title="添加安证"
		  :visible.sync="dialogVisible"
		  width="30%"
		  >
		  <el-form :model="addForm" ref="addForm" label-width="100px" class="demo-ruleForm">
		    <el-form-item
		      label="安证"
		      prop="name"
		      :rules="[
		        { required: true, message: '安证不能为空'},
		      ]"
			  
		    >
		      <el-input v-model="addForm.name" autocomplete="off" :disabled="true"></el-input>
		    </el-form-item> 
			<!-- <el-form-item label="资质" prop="name" :rules="[
		        { required: true, message: '请选择资质'},
		      ]">
			    <el-select v-model="addForm.name" placeholder="请选择资质">
			      <el-option label="总承包" value="总承包"></el-option>
			      <el-option label="专业承包" value="专业承包"></el-option>
			      <el-option label="劳务资质" value="劳务资质"></el-option>
			    </el-select>
			</el-form-item> -->
			<!-- 上传控件开始 -->
			  <el-form-item
			    label="上传文件"
			    prop="imgpath"
			    :rules="[ { required: true, message: '文件不能为空'}, ]"
			  >
			    <el-upload
			      class="avatar-uploader"
			      :action="uploadImg"
			      :show-file-list="false"
			      :on-success="handleAvatarSuccess"
			      :before-upload="beforeAvatarUpload">
			      <img v-if="addForm.imgpath" :src="addForm.imgpath" class="avatar">
			      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			      
			    </el-upload>
			  </el-form-item>
			  <!-- 上传控件结束 -->
			  <el-form-item label="安证到期时间" required>
			      <el-col :span="22">
			        <el-form-item prop="exp_time"
					:rules="[ { required: true, message: '请选择安证到期时间'}, ]">
			          <el-date-picker type="date" placeholder="选择日期" v-model="addForm.exp_time" style="width: 100%;"></el-date-picker>
			        </el-form-item>
			      </el-col>
			    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="submitForm('addForm')">提交</el-button>
		      <el-button @click="dialogVisible = false">取消</el-button>
		    </el-form-item>
		  </el-form>
		</el-dialog>
		
		<!-- 编辑 -->
		<el-dialog
		  title="编辑安证"
		  :visible.sync="editDialogVisible"
		  width="30%"
		  >
		  <el-form :model="editForm" ref="editForm" label-width="100px" class="demo-ruleForm">
		    <el-form-item
		      label="安证"
		      prop="name"
		      :rules="[
		        { required: true, message: '安证不能为空'},
		      ]"
		    >
		      <el-input v-model="editForm.name" autocomplete="off" :disabled="true"></el-input>
		    </el-form-item>
			<!-- 上传控件开始 -->
			  <el-form-item
			    label="上传文件"
			    prop="imgpath"
			    :rules="[ { required: true, message: '文件不能为空'}, ]"
			  >
			    <el-upload
			      class="avatar-uploader"
			      :action="uploadImg"
			      :show-file-list="false"
			      :on-success="editHandleAvatarSuccess"
			      :before-upload="editBeforeAvatarUpload">
			      <img v-if="editForm.imgpath" :src="editForm.imgpath" class="avatar">
			      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			      
			    </el-upload>
			  </el-form-item>
			  <!-- 上传控件结束 -->
			  <el-form-item label="安证到期时间" required>
			      <el-col :span="22">
			        <el-form-item prop="exp_time"
					:rules="[ { required: true, message: '请选择安证到期时间'}, ]">
			          <el-date-picker type="date" placeholder="选择日期" v-model="editForm.exp_time" style="width: 100%;"></el-date-picker>
			        </el-form-item>
			      </el-col>
			    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="submitEditForm('editForm')">提交</el-button>
		      <el-button @click="editDialogVisible = false">取消</el-button>
		    </el-form-item>
		  </el-form>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog
		  title="查看详情"
		  :visible.sync="detailShow"
		  width="30%"
		  >
			<el-descriptions :column="1">
				  <el-descriptions-item labelClassName="half-width" label="安证">{{detailForm.name}}</el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="图片">
					    <img :src="detailForm.imgpath" style="width: 80px; height: 80px;">
				  </el-descriptions-item>
				  <el-descriptions-item labelClassName="half-width" label="安证过期时间">{{detailForm.exp_time}}</el-descriptions-item>
				  
			</el-descriptions>
			<span slot="footer" class="dialog-footer">
				<el-button @click="detailShow = false">关闭</el-button>
			</span>
		</el-dialog>
      </div>
      <div class="page-box" v-if="list.length > 0">
        <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange" layout="sizes,prev, pager, next" :total="count"
          :page-size="limit" :page-sizes="[10, 20, 30, 40]">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import interfaces from '../../../../utils/interface.js'
  export default {
    components: {},
    data() {
      return {
		dialogVisible: false,
		editDialogVisible: false,
		detailDialogVisible: false,
		detailShow:false,
		addForm: {
			name: '《安全生产许可证》',
			imgpath: '',
			exp_time:'',
			type:2,
		},
		editForm:{
			id:'',
			name: '《安全生产许可证》',
			imgpath: '',
			exp_time:'',
		},
		detailForm:{
			id:'',
			name: '',
			imgpath: '',
			exp_time:'',
		},
		uploadImg: interfaces.uploadImages, //上传图片接口地址
        loading: false,
        page: 1,
        limit: 10,
        totalpage: 0,
        count: 0,
        status: '',
        keyword: '',
        list: [],
				previewImgs:[],
      };
    },
    created() {
      let that = this;
      that.getQualifications();
    },
    methods: {
		handleSizeChange(val) {
			let that = this;
			that.page = 1;
			that.limit = val;
			that.getQualifications();
			// console.log(`每页 ${val} 条`);
		},
		handleClose(done) {
			this.$confirm('确认关闭？')
			  .then(_ => {
				done();
			  })
			  .catch(_ => {});
		},
		editHandleClose(done) {
			this.$confirm('确认关闭？')
			  .then(_ => {
				done();
			  })
			  .catch(_ => {});
		},
		submitForm(formName) {
			let that = this;
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				//调用接口添加资质
				that.addQualifications();
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
		},
		submitEditForm(formName) {
			let that = this;
			this.$refs[formName].validate((valid) => {
			  if (valid) {
				//调用接口添加资质
				that.editQualifications();
			  } else {
				console.log('error submit!!');
				return false;
			  }
			});
		},
		handleAvatarSuccess(res, file) {
			console.log(res);
			console.log(file);
			if(res.code == 200){
				this.addForm.imgpath = res.datas.location;
			}else{
				this.$message.error(res.msg);
			}
			
		},
		beforeAvatarUpload(file) {
			let that = this;
			const isImg = file.type === 'image/jpeg' || 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;
	
			if (!isImg && !isPng) {
			  this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			}
			if (!isLt2M) {
			  this.$message.error('上传图片大小不能超过 2MB!');
			}
			
			return isImg && isLt2M;
		},
		editHandleAvatarSuccess(res, file) {
			console.log(res);
			console.log(file);
			if(res.code == 200){
				this.editForm.imgpath = res.datas.location;
			}else{
				this.$message.error(res.msg);
			}
			
		},
		editBeforeAvatarUpload(file) {
			let that = this;
			const isImg = file.type === 'image/jpeg' || 'image/png';
			const isLt2M = file.size / 1024 / 1024 < 2;
			
			if (!isImg && !isPng) {
			  this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			}
			if (!isLt2M) {
			  this.$message.error('上传图片大小不能超过 2MB!');
			}
			
			return isImg && isLt2M;
		},
		//获取资质列表
		getQualifications() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.loading = true;
		  let param = {
		    page: that.page,
		    limit: that.limit,
		    type: 2,
		    curriculum_cate_id: that.curriculum_cate_id,
		    train_type_id: that.train_type_id,
		    keyword: that.keyword,
		  };
		  that.$request.getQualifications(param).then((res) => {
		    if (res.code == 200) {
		      that.list = res.datas;
		      if (that.page + 1 < res.total_page) {
		        that.page = that.page + 1;
		      }
					
					if(that.list.length > 0){
						that.list.forEach(function(item, index) {
							that.previewImgs.push(item.imgpath);
						})
					}
		
		      that.totalpage = res.total_page;
		      that.count = res.count;
		      that.loading = false;
		    }
		  });
		},
		addQualifications() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.addForm.exp_time = new Date(this.addForm.exp_time).getTime() / 1000;
		  that.loading = true;
		  let param = that.addForm;
		  that.$request.addQualifications(param).then((res) => {
		    if (res.code == 200) {
				this.$message.success(res.msg);
				that.getQualifications();
				that.dialogVisible = false;
				that.addForm.name = '';
				that.addForm.imgpath = '';
				that.addForm.exp_time = '';
		    }else{
				this.$message.error(res.msg);
			}
		  });
		},
		editQualifications() {
		  let that = this;
		  //that.$store.commit('showLoading')
		  that.editForm.exp_time = new Date(this.editForm.exp_time).getTime() / 1000;
		  that.loading = true;
		  let param = that.editForm;
		  that.$request.editQualifications(param).then((res) => {
		    if (res.code == 200) {
				this.$message.success(res.msg);
				that.getQualifications();
				that.editDialogVisible = false;
		    }else{
				this.$message.error(res.msg);
			}
		  });
		},
      //点击页码
      handleCurrentChange(val) {
        let that = this;
        that.page = val;
        that.getQualifications();
      },
      //搜索
      searchBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.getQualifications();
      },
      //重置搜索
      resetBtn() {
        let that = this;
        that.page = 1;
        that.totalpage = 0;
        that.curriculum_cate_id = undefined;
        that.train_type_id = undefined;
        that.keyword = '';
        that.getQualifications();
      },
	  addBaomingClick(data){
		  let that=this;
		  //报名成功，跳转到支付页面
		  that.$router.push({
		    'path': '/company/applicant',
		    query: {
		      curriculum_id: data.id,
		    }
		  })
	  },
	  editClick(data){
		  let that = this;
		  that.editForm.id = data.id;
		  that.editForm.name = data.name;
		  that.editForm.imgpath = data.imgpath;
		  that.editForm.exp_time = data.exp_time;
		  that.editDialogVisible = true;
	  },
	  detailClick(data){
		  let that = this;
		  that.detailForm.id = data.id;
		  that.detailForm.name = data.name;
		  that.detailForm.imgpath = data.imgpath;
		  that.detailForm.exp_time = data.exp_time;
		  that.detailShow = true;
	  },
	  delClick(data){
		  let that = this;
		  // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
		  that.$confirm('是否删除该条记录？', '警告', {
		    confirmButtonText: '确定',
		    cancelButtonText: '取消',
		    type: 'warning'
		  }).then(() => {
			  let param = {
				  id:data.id,
			  };
			  that.$request.delQualifications(param).then((res) => {
			    if (res.code == 200) {
					this.$message.success(res.msg);
					that.getQualifications();
					that.editDialogVisible = false;
			    }else{
					this.$message.error(res.msg);
				}
			  });
		    
		  }).catch(() => {
		    // 如果取消跳转地址栏会变化，这时保持地址栏不变
		    window.history.go(1)
		  })
	  },
    },
  };
</script>

<style scoped>
	/deep/.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  /deep/.avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .red-text {
	    color: red;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
	  /deep/.half-width {
	    width: 26%;
	  }
  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }

  .page {
    background-color: #f5f6fa;
    width: calc(100% - 220px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 1.25rem 1.25rem 1.88rem;
  }

  .page-box {
    margin: 1.65rem 0;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }

  .section_3 {
    /* margin-right: 1.25rem; */
    padding: 0.94rem 0.94rem;
    align-self: auto;
    background-color: #ffffff;
    border-radius: 0.63rem;
    /* width: 71.56rem; */
  }

  .group_3 {
    margin-right: 0.31rem;
    overflow: hidden;
    border-bottom: solid 0.016rem #c0c4cc;
  }

  .text-wrapper {
    padding: 0.22rem 0 1.28rem;
    width: 6.75rem;
    border-bottom: solid 0.16rem #0074ff;
  }

  .text_2 {
    color: #0074ff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .group_4 {
    margin-top: 1.69rem;
    /* align-self: flex-start; */
    overflow: hidden;
    /* width: 59.69rem; */
  }

  .space-x-8>*:not(:first-child) {
    margin-left: 0.5rem;
  }

  .group_5 {
    overflow: hidden;
    width: 12.63rem;
    /* height: 2.19rem; */
  }

  .text_3 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-45>*:not(:first-child) {
    margin-left: 2.81rem;
  }

  .section_4 {
    flex-shrink: 0;
    padding: 0.59rem 0.5rem 0.66rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_4 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .image_6 {
    margin-right: 0.16rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }

  .group_6 {
    margin-left: 0.94rem;
    overflow: hidden;
    width: 10.69rem;
    height: 2.19rem;
  }

  .text_5 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-14>*:not(:first-child) {
    margin-left: 0.88rem;
  }

  .text_7 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_11 {
    margin-left: 1rem;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_1 {
    margin-left: 0.53rem;
    padding: 0.59rem 0 0.72rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }

  .text_12 {
    margin-left: 0.5rem;
    margin-right: 0.56rem;
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_2 {
    margin-left: 0.94rem;
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_13 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
    cursor: pointer;
  }

  .text-wrapper_3 {
    margin-left: 0.91rem;
    padding: 0.63rem 0 0.72rem;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
    cursor: pointer;
  }

  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-y-14>*:not(:first-child) {
    margin-top: 0.88rem;
  }

  .group_7 {
    padding: 1.25rem 0.44rem 0;
  }

  .section_5 {
    margin-bottom: 1rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .section_6 {
    padding: 0.53rem 1.13rem 0.59rem;
    background-color: #fbfcff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_15 {
    align-self: center;
    color: #9a9da7;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_4 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #fef0f0;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #fde2e2;
  }

  .text_17 {
    color: #f56c6c;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .space-y-10>*:not(:first-child) {
    margin-top: 0.63rem;
  }

  .group_8 {
    margin: 0.94rem 1.53rem 0 1.16rem;
    overflow: hidden;
  }

  .text_19 {
    margin-bottom: 0.13rem;
    color: #3d3d3d;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }

  .space-x-6>*:not(:first-child) {
    margin-left: 0.38rem;
  }

  .group_10 {
    padding: 0.16rem 0 0.16rem 0.094rem;
    overflow: hidden;
    /* width: 8rem; */
    height: 1.25rem;
  }

  .text_21 {
    flex-shrink: 0;
    color: #3d3d3d;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_23 {
    margin-top: 0.19rem;
    flex-shrink: 0;
    color: #ff5f5a;
    font-size: 1rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.66rem;
  }

  .space-x-10>*:not(:first-child) {
    margin-left: 0.63rem;
  }

  .group_11 {
    align-self: flex-start;
  }

  .text-wrapper_5 {
    flex: 1 1 auto;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 4.25rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_25 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .text-wrapper_6 {
    flex-shrink: 0;
    padding: 0.47rem 0 0.66rem;
    background-color: #ffffff;
    border-radius: 0.31rem;
    width: 2.75rem;
    height: 1.88rem;
    border: solid 0.031rem #b3d8ff;
  }

  .text_27 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .section_7 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_29 {
    color: #7d7d8c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_30 {
    align-self: center;
  }

  .group_12 {
    margin-right: 0.47rem;
  }

  .text-wrapper_7 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    color: #fff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text-wrapper_71 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_31 {
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text_311 {
    color: #fff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_8 {
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 5.63rem;
    height: 2.19rem;
    cursor: pointer;
  }

  .text_33 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .text-wrapper_9 {
    margin-right: 0.41rem;
    padding: 0.47rem 0 0.66rem;
    background-color: #f4f4f5;
    border-radius: 0.31rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #e9e9eb;
  }

  .text_36 {
    color: #909399;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }

  .group_14 {
    align-self: flex-start;
  }

  .text-wrapper_10 {
    margin-top: 0.88rem;
    padding: 1.03rem 0 1.16rem;
    background-color: #ffffff;
    /* width: 68.75rem; */
    border: solid 0.063rem #dcdfe6;
  }

  .text_43 {
    margin-left: 1.13rem;
  }

  .group_16 {
    align-self: flex-start;
  }

  .group_18 {
    padding: 0.16rem 0;
    overflow: hidden;
    width: 8rem;
    height: 1.25rem;
  }

  .group_19 {
    align-self: flex-start;
  }

  .section_8 {
    margin-top: 0.88rem;
    padding: 0.41rem 1.13rem 0.41rem 1.13rem;
    background-color: #ffffff;
    border: solid 0.063rem #dcdfe6;
  }

  .text_60 {
    align-self: center;
  }

  .text-wrapper_11 {
    padding: 0.69rem 0 0.47rem;
    background-color: #f5f8ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }

  .text-wrapper_12 {
    padding: 0.69rem 0 0.47rem;
    background-color: #0074ff;
    border-radius: 0.31rem 0.31rem 0 0;
    width: 5.63rem;
    height: 1.97rem;
  }
  
  
</style>
